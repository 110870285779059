import React from "react";
import ReactDOM from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';

import Icon from "./Icon";
import ResourceLinks from "./ResourceLinks";
import SelectionSlideout from "./SelectionSlideout";
import SelectionToken from "./SelectionToken";
import SelectionTokenText from "./SelectionTokenText";

class SelectionContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conjugationEntryUrl: null,
      loadingTranslations: true,
      translationEntryUrl: null,
      translations: null,
      ttsAvailable: false
    };
  }

  componentDidMount() {
    this.loadTranslations();
    this.initTts();
  }

  initTts() {
    // run check to load voices
    this.ttsTimeoutCount = 0;
    this.checkForTts();
  }

  checkForTts() {
    const ttsAvailable = this.isSystemTtsAvailable();
    this.setState({ ttsAvailable });
    this.ttsTimeoutCount += 1;
    if(!ttsAvailable && this.ttsTimeoutCount < 5) {
      this.ttsTimeout = setTimeout(() => this.checkForTts(), 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.ttsTimeout);
  }

  isSystemTtsAvailable() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.systemTtsAvailable(targetLanguageIso, targetLanguageCode);
  }

  getSystemTtsVoices() {
    const { targetLanguageIso, targetLanguageCode } = this.props;
    return window.clozemaster.getSystemTtsVoices(targetLanguageIso, targetLanguageCode);
  }

  playTts() {
    const { selection } = this.props;
    const voices = this.getSystemTtsVoices();
    const voice = voices[0]; // voices[Math.floor(Math.random()*voices.length)];
    const u = new SpeechSynthesisUtterance(selection);
    u.voice = voice;
    u.lang = voice.lang;
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(u);
  }

  loadTranslations() {
    this.setState({ loadingTranslations: true });

    const {
      baseLanguageIso,
      selection,
      targetLanguageIso,
      tokenizeableId,
      tokenizeableType,
      userSelectionTranslationsUrl
    } = this.props;

    $.ajax({
      data: {
        from: targetLanguageIso,
        to: baseLanguageIso,
        tokenizeable_id: tokenizeableId,
        tokenizeable_type: tokenizeableType,
        translate: selection
      },
      method: 'post',
      url: userSelectionTranslationsUrl
    })
      .done((data) => {
        console.log(data);
        this.setState({
          loadingTranslations: false,
          resourceLinks: data.resourceLinks,
          resourceLinksUrl: data.resourceLinksUrl,
          token: data.token,
          tokenText: data.tokenText,
          translations: data.translations && data.translations.map((t) => t.translatedText).join('; ')
        });
      })
      .fail((jqXHR, textStatus, errorThrown) => {
        if(jqXHR.status === 401) {
          this.setState({
            loadingTranslations: false,
            translations: false
          });
        }
        else {
          alert('Oh no! There was an error loading translations. Sorry about that. Please let us know if you see this error message again.');
        }
      })
      .always(() => {
        this.loadTranslationEntry();
      });
  }

  loadTranslationEntry() {
    const { searchTranslationEntriesUrl, selection } = this.props;

    if(!searchTranslationEntriesUrl) {
      return false;
    }

    $.ajax({
      data: { term: selection },
      url: searchTranslationEntriesUrl
    })
      .done((data) => {
        if(!data || !data.translationEntry) {
          return null;
        }
        const { translationEntry } = data;
        const { conjugationEntry } = translationEntry;
        this.setState({
          conjugationEntryUrl: conjugationEntry && conjugationEntry.webUrl,
          translationEntryUrl: translationEntry.webUrl
        });
      })
      .fail(() => {
        // do nothing
      });
  }

  renderLinks() {
    const { loadingTranslations, resourceLinks, resourceLinksUrl } = this.state;

    if(loadingTranslations) {
      return null;
    }

    if(resourceLinks) {
      const { openResourceLinksEditor, selection, targetLanguageCode } = this.props;
      return (
        <ResourceLinks
          onEditClick={openResourceLinksEditor}
          query={selection}
          resourceLinks={resourceLinks}
          targetLanguageCode={targetLanguageCode}
        />
      );
    }

    // replace with ResourceLinks component
    // pass language pairing resource links
    // maybe association on lp, but jsonb on ulp?
    // languagePairing.resourceLinks
    // languagePairing.defaultResourceLinks
    // can delete any/all, can add your own, can add from default list
    const { baseLanguageIso, baseLanguageCode, targetLanguageCode, targetLanguageEnglishName, targetLanguageIso } = this.props;
    const { conjugationEntryUrl, translationEntryUrl } = this.state;
    const content = this.props.selection;
    const links = [
      { name: 'Google Translate', url: window.clozemaster.getGoogleTranslateUrl(targetLanguageIso, baseLanguageIso, content) },
      { name: 'Google Images', url: "https://www.google.com/images?q=" + encodeURI(content) },
      { name: 'Forvo', url: 'https://forvo.com/search/' + encodeURI(content) + '/' },
      { name: 'Wiktionary', url: this.getWiktionaryUrl() },
      { name: 'Tatoeba', url: 'https://tatoeba.org/eng/sentences/search?from=' + targetLanguageCode + '&to=' + baseLanguageCode + '&query=' + encodeURI(content) },
    ];

    if(targetLanguageIso === 'es') {
      links.push({ name: 'SpanishDict', url: 'https://www.spanishdict.com/translate/' + encodeURI(content) });
    }

    if(targetLanguageIso === 'he') {
      links.push({ name: 'Morfix', url: 'https://www.morfix.co.il/' + encodeURI(content) });
      links.push({ name: 'Pealim', url: 'https://www.pealim.com/search/?q=' + encodeURI(content) });
    }

    if(targetLanguageIso === 'it') {
      links.push({ name: 'Treccani', url: 'https://www.treccani.it/vocabolario/ricerca/' + encodeURI(content) + '/' });
    }

    if(targetLanguageIso === 'ja') {
      links.push({ name: 'Jisho', url: 'https://jisho.org/search/' + encodeURI(content) + '/' });
    }

    if(targetLanguageIso === 'de') {
      links.push({ name: 'dict.cc', url: 'https://www.dict.cc/?s=' + encodeURI(content) });
    }

    if(targetLanguageIso === 'jbo') {
      links.push({ name: 'la sutysisku', url: 'https://la-lojban.github.io/sutysisku/jb/#seskari=cnano&sisku=' + encodeURI(content) });
    }

    if(targetLanguageIso === 'tr') {
      links.push({ name: 'Tureng', url: 'https://tureng.com/en/turkish-english/' + encodeURI(content) });
    }

    if(translationEntryUrl) {
      links.push({ name: "Translation Entry", url: translationEntryUrl });
    }

    if(conjugationEntryUrl) {
      links.push({ name: "Conjugation Entry", url: conjugationEntryUrl });
    }

    return (
      <div className="links">
        {links.sort((a, b) => a.name > b.name ? 1 : -1).map((link, i) => (
          <div className="link" key={link.url}>
            <a href={link.url} target="_blank">{link.name} <span className="glyphicon glyphicon-new-window"></span></a>
          </div>
        ))}
      </div>
    );
  }

  getWiktionaryUrl() {
    const { baseLanguageIso, selection, targetLanguageEnglishName } = this.props;
    const query = encodeURI(targetLanguageEnglishName === "German" ? selection : selection.toLowerCase());
    return 'https://' + baseLanguageIso + '.wiktionary.org/wiki/' + query + '#' + targetLanguageEnglishName;
  }

  renderTranslations() {
    if(this.state.loadingTranslations) {
      return <p className="translations">Loading...</p>;
    }

    if(this.state.translations === false) {
      return (
        <p>
          Get automatic Google translations for any word or selected text with <span className="joystix">Clozemaster Pro</span>.
          <br/>
          Take control of your learning. Get fluent faster. <a href="/pro"><strong>Get Pro today!</strong></a>
        </p> 
      );
    }

    return (
      <div className="translations">
        <div dangerouslySetInnerHTML={{ __html: this.state.translations }} />
        <p className="attribution">
          <a href="https://translate.google.com/" target="_blank"><img src="/assets/google-translate-attribution/png/color-short-db1357358c54ba873f60dae0b7fab45f96d57c1ed7e3205853bc64be7941e279.png" alt="Color short" /></a>
        </p>
      </div>
    );
  }

  renderControlBtn(text, icon, onClick) {
    return (
      <button className="btn btn-success btn-xs btn-block joystix" onClick={onClick}>
        <span className={'glyphicon glyphicon-' + icon}></span> {text}
      </button>
    );
  }

  renderControls() {
    const {
      isSearchCollectionAvailable,
      onAddToCollectionClick,
      onSearchCollectionClick,
      onSearchSentencesClick
    } = this.props;

    return (
      <div className="controls">
        {!!onAddToCollectionClick && this.renderControlBtn('Add to Collection', 'plus', () => onAddToCollectionClick())}
        {!!isSearchCollectionAvailable && this.renderControlBtn('Collection Sentences', 'search', () => onSearchCollectionClick())}
        {this.renderControlBtn((!!isSearchCollectionAvailable ? 'All ' : '') + 'Sentences', 'search', () => onSearchSentencesClick())}
      </div>
    );
  }

  // renderWiktionaryEntry() {
  //   const { wiktionaryEntry } = this.state;

  //   if(!wiktionaryEntry) {
  //     return null;
  //   }

  //   return (
  //     <div className="text-left wiktionary-entry">
  //       <div className="definitions">
  //         {wiktionaryEntry.map((e) => (
  //           <div>
  //             {e.definitions.map((d) => (
  //               <p>
  //                 <strong>{d.text[0]}</strong> <em>{d.partOfSpeech}</em>
  //                 <ul>
  //                   {d.text.slice(1).map((t) => <li>{t}</li>)}
  //                 </ul>
  //               </p>
  //             ))}
  //           </div>
  //         ))}
  //       </div>
  //       <div className="text-right">
  //         <small>
  //           From <a href={this.getWiktionaryUrl()} target="_blank">Wiktionary <span className="glyphicon glyphicon-new-window"></span></a>
  //         </small>
  //       </div>
  //     </div>
  //   );
  // }

  renderAudioControl() {
    const { tokenText, ttsAvailable } = this.state;

    let handler = null;

    if(tokenText && tokenText.ttsUrl) {
      handler = () => {
        const audio = new Audio(tokenText.ttsUrl);
        audio.play();
      };
    }
    else if(ttsAvailable) {
      handler = () => this.playTts();
    }
    else {
      return null;
    }

    return (
      <button className="btn btn-default btn-xs" onClick={handler} style={{ marginLeft: 5 }}><Icon name="volume-up" /></button>
    );
  }

  renderToken() {
    const { token } = this.state;

    return (
      <SelectionToken
        token={token}
      />
    );
  }

  renderTokenText() {
    const { tokenText } = this.state;

    return (
      <SelectionTokenText tokenText={tokenText} />
    );
  }

  render() {
    return (
      <div id="translation-popover">
        <button type="button" className="close" aria-label="Close" onClick={() => this.props.onClose()}><span aria-hidden="true">&times;</span></button>
        <div className="selection"><strong>{this.props.selection}</strong>{this.renderAudioControl()}</div>
        {this.renderLinks()}
        {this.renderTranslations()}
        {this.renderToken()}
        {this.renderTokenText()}
        {this.renderControls()}
        {this.props.switchToSlideout && (
          <div className="text-right">
            <button className="btn btn-link btn-xs" onClick={this.props.switchToSlideout}>Use Slideout <Icon name="arrow-right" /></button>
          </div>
        )}
      </div>
    );
  }
}

export default class SelectionPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slideout: localStorage.getItem("selectionPopoverSlideout") !== "false"
    };
  }

  renderSelectionContent() {
    return (
      <SelectionContent
        {...this.props}
        switchToSlideout={() => this.useSlideout(true)}
      />
    );
  }

  // getter setter for whether slideout should be used
  useSlideout(slideout) {
    if(slideout !== undefined) {
      localStorage.setItem("selectionPopoverSlideout", slideout ? "true" : "false");
      // save to game settings for now to see if anyone uses this toggle
      const { gameSettings, gameSettingsUrl } = this.props;
      if(gameSettings && gameSettingsUrl) {
        $.ajax({
          data: {
            settings: Object.assign(
              {},
              this.props.gameSettings,
              { selectionPopoverSlideout: slideout ? "true" : "false" })
          },
          method: "post",
          url: this.props.gameSettingsUrl
        });
      }
      this.setState({ slideout });
    }

    return localStorage.getItem("selectionPopoverSlideout") !== "false";
  }

  render() {
    if(this.useSlideout()) {
      return (
        <>
          {this.props.children}
          {this.props.visible && (
            <SelectionSlideout
              {...this.props}
              onHidden={this.props.onClose}
              switchToPopover={() => this.useSlideout(false)}
            />
          )}
        </>
      );
    }

    return (
      <Manager>
        <Reference>
          {({ ref }) => <span ref={ref}>{this.props.children}</span>}
        </Reference>
        {this.props.visible && ReactDOM.createPortal(
          <Popper placement="bottom" modifiers={{ flip: { enabled: false } }}>
            {({ ref, style, placement, arrowProps }) => (
              <div className="popover bottom selection-popover" role="tooltip" ref={ref} style={{ ...style, display: 'block' }} data-placement={placement}>
                <div className="arrow" />
                <div className="popover-content">{this.renderSelectionContent()}</div>
              </div>
            )}
          </Popper>,
          document.querySelector('body')
        )}
      </Manager>
    );
  }
}
